import './TermsOfUse.scss';

import { AccordionItem, Button } from '@b4valuenet/ui-react';

import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useAppSelector } from '../../redux/hooks';
import { Accordion } from '@b4valuenet/ui-react';

const TermsOfUse = () => {
    const { t } = useTranslation();

    const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);

    return (
        <div className='sub-page terms-of-use'>
            <h1>{t('Common.TermsOfUse.Text')}</h1>
            <p>{t('TermsOfUsePage.Block2.Text')}</p>
            <p>{t('TermsOfUsePage.Block3.Text')}</p>
            <p>{t('TermsOfUsePage.Block4.Text')}</p>
            {!isLoggedIn && (
                <Button
                    linkTo={'/'}
                    isLink={true}
                    icon='chevron-left'
                    text={t('PasswordForgotten.CancelButton.Text')}
                    primary={true}
                />
            )}
            <br />
            <hr />
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {t('TermsOfUsePage.LatestContent.Text')}
            </ReactMarkdown>
            <Accordion>
                <AccordionItem
                    title={<h1>{t('Common.OldTermsOfUse.Text')}</h1>}
                    expanded={false}
                    body={
                        <div>
                            <p>{t('TermsOfUsePage.Block2.Text')}</p>
                            <p>{t('TermsOfUsePage.Block5.Text')}</p>

                            <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                {t('TermsOfUsePage.Content.Text')}
                            </ReactMarkdown>
                        </div>
                    }
                />
            </Accordion>
        </div>
    );
};

export default TermsOfUse;

